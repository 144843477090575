export const formsPolice = [
    {
        s: 'А1240',
        r: '177'
    },
    {
        s: 'Б6566',
        r: '01'
    },
    {
        s: 'О1190',
        r: '45'
    },
    {
        s: 'М1778',
        r: '777'
    },
    {
        s: 'Е2023',
        r: '42'
    },
    {
        s: 'Х6691',
        r: '34'
    },
    {
        s: 'Р8854',
        r: '24'
    },
    {
        s: 'Т4564',
        r: '36'
    },
    {
        s: 'У5581',
        r: '42'
    },
]