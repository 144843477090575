export const formsDip = [
    {
        s: '151CD1',
        r: '77'
    },
    {
        s: '336AL2',
        r: '01'
    },
    {
        s: '121GT6',
        r: '54'
    },
    {
        s: '987BN9',
        r: '36'
    },
    {
        s: '544EN4',
        r: '42'
    },
    {
        s: '676YT8',
        r: '46'
    },
    {
        s: '119UI7',
        r: '32'
    },
    {
        s: '882WE3',
        r: '61'
    },
    {
        s: '765KL1',
        r: '77'
    },
]