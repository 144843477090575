export const forms = [
    {
        s: 'А729АА',
        r: '161'
    },
    {
        s: 'Б640ББ',
        r: '01'
    },
    {
        s: 'О007УС',
        r: '116'
    },
    {
        s: 'М123СО',
        r: '777'
    },
    {
        s: 'Е202КН',
        r: '420'
    },
    {
        s: 'Х777ХМ',
        r: '23'
    },
    {
        s: 'Т456МР',
        r: '36'
    },
    {
        s: 'У555ТА',
        r: '42'
    },
    {
        s: 'М349НН',
        r: '28'
    },
    {
        s: 'В777ОР',
        r: '777'
    },
]